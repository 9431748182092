// ContactForm.js
import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import './ContactForm.css';

const ContactForm = () => {
  const [captchaToken, setCaptchaToken] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission state
  const recaptchaRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setStatusType('error');
      setStatusMessage('Please complete the CAPTCHA.');
      autoClearStatusMessage();
      return;
    }

    setIsSubmitting(true); // Set submitting state to true to hide the form and show awaiting message
    setStatusMessage('Awaiting confirmation from mail server...');

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      setStatusType('success');
      setStatusMessage('Message sent successfully!');
    } catch (error) {
      console.error('Failed to send message:', error);
      setStatusType('error');
      setStatusMessage('Failed to send message. Please try again later.');
    }

    setIsSubmitting(false);
    setFormData({ name: '', email: '', message: '' });

    // Ensure the recaptcha reference is still valid before resetting it
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }

    autoClearStatusMessage();
  };

  const autoClearStatusMessage = () => {
    setTimeout(() => {
      setStatusMessage('');
      setStatusType('');
    }, 15000);
  };

  return (
    <div id="contact" className="contact-form-container">
      <h2>Contact</h2>
      {statusMessage && (
        <div className={`status-message ${statusType}`}>
          {statusMessage}
        </div>
      )}
      {!isSubmitting && !statusType ? (
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group captcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              onChange={handleCaptchaChange}
            />
          </div>
          <button type="submit" className="submit-button">Send</button>
        </form>
      ) : null}
    </div>
  );
};

export default ContactForm;

import React from 'react';
import './ServicesSection.css';
import interimmanagement from '../assets/images/Service1.png';
import OpsConsultancy from '../assets/images/Service2.png';
import TransitionAdvice from '../assets/images/Service3.png';

const ServicesSection = () => {
  return (
    <div id="services" className="ServicesSection">
      {/* Section with text on the left and boxes on the right */}
      <div className="service-highlight">
        {/* Left side: Title and Text */}
        <div className="highlight-text">
          <h2>Transform your business with FlashCube</h2>
          <p>
            At FlashCube we always start with the why and discuss your needs, ideas, and objectives. 
            From active listening to hands-on action - we will guide your business to transform and grow sustainably. <br /><br />
            Ready to jump in on the spot while thoughtful and phased when deeper analysis is needed.
            Using our extensive experience in Leadership, Technology, Operations, Safety, and Innovation in the petrochemical industry, 
            we can bring real long term value to your business. <br /><br />
            Would you like to know more about our Interim Management or Consultancy? Please check out our info boxes below and reach out to us.
          </p>

          {/* Updated CTA button linking to contact section */}
          <a href="#contact" className="cta-button">Get in touch</a>
        </div>

        {/* Right side: Grid of boxes */}
        <div className="highlight-boxes">
          <div className="highlight-box">
            <i className="fa-solid fa-graduation-cap"></i>
            <h3>Know-how</h3>
            <p>We combine fundamental knowledge with best practices, innovation, digital solutions & extensive leadership experience.</p>
          </div>
          <div className="highlight-box">
            <i className="fa-solid fa-rocket"></i>
            <h3>Excellence</h3>
            <p>We generate and implement safe, robust, practical & well thought-out solutions at and beyond international standards.</p>
          </div>
          <div className="highlight-box">
            <i className="fas fa-users"></i>
            <h3>Teamwork</h3>
            <p>We develop and implement solutions on-site with your team to drive durable results based on human factors and continuous learning.</p>
          </div>
          <div className="highlight-box">
            <i className="fa-solid fa-hand-holding-hand"></i>
            <h3>Responsible</h3>
            <p>We stand for care and responsibility towards society, the environment, employees and shareholders.</p>
          </div>
        </div>
      </div>

      {/* Service Items in a Row */}
      <div className="services-container">
        <div className="service-item">
          <img src={interimmanagement} alt="Interim Management" />
          <h2>Interim Management</h2>
          <div className="service-info">
            Elevate your operations with my interim management service. Whether you need a dynamic team leader, seasoned production manager, or impactful community leader, 
            I'm here to step in and drive success. Don't let temporary gaps hinder your progress. With extensive experience managing teams up to 160 staff, 
            I can seamlessly guide and integrate into your leadership, production, and technology teams.
          </div>
        </div>
        <div className="service-item">
          <img src={OpsConsultancy} alt="Operational Excellence" />
          <h2>Operational Excellence</h2>
          <div className="service-info">
            Transform your business with targeted Operational Excellence consultancy. Whether you're grappling with safety, environmental concerns, reliability issues, 
            or production challenges, I'll work alongside your teams to achieve a significant leap in performance and lay the foundation for continuous improvement. 
            From quick wins to long-term strategic improvements, let's collaborate to unlock your organization's full potential and set new benchmarks in your industry.
          </div>
        </div>
        <div className="service-item">
          <img src={TransitionAdvice} alt="Sustainability" />
          <h2>Sustainability</h2>
          <div className="service-info">
            As an advisor and sparring partner, I provide strategic insights, roadmap development, in-depth scans, and techno-economic analyses for business investments. 
            I also facilitate dynamic workshops to spark innovation within your team. Whether you're starting your sustainability journey or refining existing strategies, 
            my personalized approach ensures informed, impactful decisions that turn environmental challenges into competitive sustainability advantages.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;

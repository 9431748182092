import React, { useState, useEffect } from 'react';
import './Footer.css';
import PrivacyStatement from '../components/PrivacyStatement.js';
import Terms from '../components/Terms.js';
import favicon from '../assets/images/favicon.png'; // Import your favicon

const Footer = () => {
  const [visibleContent, setVisibleContent] = useState(''); // For storing which content to show

  const handleShowContent = (content) => {
    setVisibleContent(content); // Set the visible content (Privacy Policy or Terms of Use)

    // After a slight delay, scroll to ensure the content is visible
    setTimeout(() => {
      const footerElement = document.querySelector('.footer-content-placeholder');
      const rect = footerElement.getBoundingClientRect();
      const absoluteElementTop = rect.top + window.pageYOffset;

      // Scroll to the top of the expanded content plus a margin to ensure full visibility
      window.scrollTo({
        top: absoluteElementTop - 100, // Scroll slightly above the top of the footer content
        behavior: 'smooth',
      });
    }, 300); // Slightly increased delay to allow the content to fully expand before scrolling
  };

  const handleCloseContent = () => {
    setVisibleContent(''); // Close the content box when clicking 'Close' or elsewhere
  };

  const handleLinkClick = (e, content) => {
    e.preventDefault(); // Prevent default behavior of the <a> tag
    e.stopPropagation(); // Prevent the click from bubbling up
    handleShowContent(content);
  };

  return (
    <footer className="footer" onClick={handleCloseContent}>
      {/* Top section with the logo and breadcrumb link */}
      <div className="footer-top">
        <img src={favicon} alt="Company Logo" className="footer-logo" />
        <a href="#">FlashCube Consultancy</a>
      </div>

      {/* Dynamic content placeholder */}
      <div className={`footer-content-placeholder ${visibleContent ? 'open' : ''}`}>
        {visibleContent && (
          <div className="text-box" onClick={(e) => e.stopPropagation()}>
            {visibleContent === 'privacy' && (
              <PrivacyStatement handleCloseContent={handleCloseContent}/>
            )}
            {visibleContent === 'terms' && (
              <div>
                <Terms handleCloseContent={handleCloseContent}/>
               </div>
            )}
          </div>
        )}
      </div>

      {/* Footer bottom section */}
      <div className="footer-bottom">
        <p>Copyright © 2024 FlashCube B.V. All rights reserved.</p>
        <div className="footer-bottom-links">
          <a href="#" onClick={(e) => handleLinkClick(e, 'privacy')}>Privacy Policy</a>
          <a href="#" onClick={(e) => handleLinkClick(e, 'terms')}>Terms & Conditions</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

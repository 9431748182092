import React from 'react';
import './Terms.css';

const Terms = ({ handleCloseContent }) => {
  return (
    <div className="terms-content">
      <h4>General Terms and Conditions</h4>
      <p>
        Please contact FlashCube via our Contact Form or email directly such that we can discuss our General Terms and Conditions with you. 
        This web-summary gives a high level overview of our terms but has no legally binding power. We can provide our full terms in English and Dutch. 
      </p>
      <p>
        Our terms apply to every FlashCube Consultancy offer, quotation, activity, and contract agreement. Offers and quotations from FlashCube are 
        non-binding and valid for up to 30 days with prices excluding VAT and expenses. 
      </p>
      <p>
        We will execute any assignment to the best of our knowledge and ability and in accordance with the requirements of good craftsmanship. We have 
        discretion in timing and allocation of work and may engage third parties. 
      </p>
      <p>
        Agreements are typically for a fixed term but can be extended if agreed upon in writing. Both parties can terminate early with a one-month notice period. 
        Fee, payment terms, complaint handling, suspension, liabilities and termination are further detailed out in the full Terms and Conditions.
      </p>
      <p>
        Confidentiality is emphasized, with both parties required to maintain the confidentiality of information obtained during the assignment. 
        Intellectual property rights of FlashCube remain with FlashCube.
      </p>
      <p>
        Our liability is in any case limited to our insurance coverage, and FlashCube B.V. will not accept any legal liability, material or immaterial, 
        direct or indirect, towards damages related to assignments, including any losses related to safety, production, business, or other.
      </p>
      <p>
        Dutch law applies to all legal relationships, with the distric court in The Hague (NL) having jurisdiction, while parties will attempt to 
        find mutual resolution before resorting to legal action.
      </p>

      <h4>Contact</h4>
      <p>For any further questions, you can contact us at:</p>
      <p><strong>FlashCube B.V.</strong></p>
      <p>Email: <a href="mailto:info@flashcube.nl">info@flashcube.nl</a></p>

      {/* Close button */}
      <p className="close-btn" onClick={handleCloseContent}>Close</p>
    </div>
  );
};

export default Terms;

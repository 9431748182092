import React from 'react';
import './AboutSection.css';
import profileImage from '../assets/images/Dennis300.JPG'; // Profile image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'; // LinkedIn icon from FontAwesome
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'; // Email icon from FontAwesome


const AboutSection = () => {
  return (
    <div id="about" className="about-section">
      <div className="about-content">
        {/* Image and credentials inside one box */}
        <div className="about-image-credentials">
          {/* Profile Image */}
          <div className="about-image">
            <img src={profileImage} alt="Dennis van Hessem" />
          </div>
          {/* Credentials */}
          <div className="credentials">
            <h3>Dennis van Hessem</h3>
            <p className="title">Principal Consultant & Founder</p>
            <div className="credential-links">
              <div className="credential-item">
                <FontAwesomeIcon icon={faLinkedinIn} className="linkedin-icon" />
                <a
                  href="https://www.linkedin.com/in/dennisvanhessem"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin-link"
                >
                  dennisvanhessem
                </a>
              </div>
              <div className="credential-item">
                <FontAwesomeIcon icon={faEnvelope} className="email-icon" aria-hidden="true" />
                <a 
                    href="mailto:dennis@flashcube.nl" className="email-link"
                   target="_blank"
                    rel="noopener noreferrer"
                  >
                    dennis@flashcube.nl
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Text content */}
        <div className="about-text-content">
          <h2>About Me</h2>
          <p>
            With over 20 years of leadership in operations, technology, and business development, I specialize in driving innovative sustainable change.
            My expertise spans production, safety, design, turnarounds, automation, business and project development. My focus is always 
            on co-creating solutions that align with your team’s strengths.
          </p>
          <p>
            From managing world-scale manufacturing assets to leading energy transition projects, my goal is to significantly improve industrial sustainability ,
            simplify processes, improve performance and foster sustainable growth through clear communication, leadership and collaboration.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;

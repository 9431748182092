// src/Views/HomePage.js
import React, { useState } from 'react';
import './HomePage.css';
import Topfold from './Topfold.js';
import ServicesSection from './ServicesSection.js';
import AboutSection from './AboutSection.js';
import ContactSection from './ContactForm.js';
import Footer from './Footer.js'; // Import Footer
import Banner from '../components/Banner.js'; // Import the Banner component
import ContactForm from './ContactForm.js';

const HomePage = () => {
     return (
        <div className="flashcube-homepage">
              <Topfold />
              <ServicesSection />
              <AboutSection />
              <ContactForm />
              <Footer /> {/* Add Footer */}
        </div>
    );
};

export default HomePage;


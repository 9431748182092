import React, { useState } from 'react';
import flashcubenamelogo from '../assets/images/Logo_Horizontal_FlashCube.webp';
import './Topfold.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Topfold = () => {
  // State to track if the menu is open
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the menu open or closed
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <div id="home" className="Topfold">
      {/* Navigation Bar */}
      <nav className="Topfold-navbar">
        <div className="navbar-container">
          <img src={flashcubenamelogo} alt="FlashCube Logo" className="navbar-logo" />

          {/* Hamburger icon */}
          <div className="hamburger" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </div>

          {/* Navbar links */}
          <ul className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
            <li><a href="#home" onClick={() => setIsMenuOpen(false)}>Home</a></li>
            <li><a href="#services" onClick={() => setIsMenuOpen(false)}>Services</a></li>
            <li><a href="#about" onClick={() => setIsMenuOpen(false)}>About</a></li>
            <li><a href="#contact" onClick={() => setIsMenuOpen(false)}>Contact</a></li>
          </ul>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="Topfold-hero">
        <h1 className="hero-title">Excellence & Sustainability</h1>
        <p className="hero-subtext">Safe - Reliable - Sustainable - Profitable - Digital - Human</p>
      </div>
    </div>
  );
};

export default Topfold;

// src/App.js
import React from 'react';
import './App.css';
import HomePage from './Views/HomePage.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/free-regular-svg-icons';

console.log('Captcha Site Key:', process.env.REACT_APP_CAPTCHA_SITE_KEY);

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}
export default App;
import React from 'react';

const PrivacyStatement = ({ handleCloseContent }) => {
  return (
    <div>
      <h4>Privacy Statement</h4>
      <p>
        At FlashCube Consultancy, we are committed to protecting your personal data in accordance
        with the General Data Protection Regulation (GDPR) and Dutch law. This privacy statement
        explains how we collect, use, and safeguard your personal information.
      </p>
      <h5>What Data We Collect</h5>
      <p>
        We may collect personal data such as your name, email address, phone number, and any other
        information you provide to us through our website or communication channels.
      </p>
      <h5>How We Use Your Data</h5>
      <p>
        We use your data to:
        <ul>
          <li>Provide our services</li>
          <li>Respond to inquiries or requests</li>
          <li>Comply with legal obligations</li>
        </ul>
      </p>
      <h5>Sharing of Data</h5>
      <p>
        We do not sell your data. We may share your data with trusted third parties to
        fulfill our services to you or comply with legal requirements. 
      </p>
      <h5>Your Rights</h5>
      <p>
        You have the right to access, correct, delete, or restrict the use of your personal data.
        You also have the right to withdraw consent at any time. To exercise these rights, please
        contact us via email (below).
      </p>
      <h5>Retention of Data</h5>
      <p>
        We will retain your data for as long as necessary to fulfill the purposes outlined in this
        statement, unless a longer retention period is required by law.
      </p>
      <h5>Contact</h5>
      <p>
        For any questions regarding your data, you can contact us at:
      </p>
      <p>
        FlashCube B.V.
        <br />
        Email: <a href="mailto:info@flashcube.nl">info@flashcube.nl</a>
      </p>
      
      {/* Close button */}
      <p className="close-btn" onClick={() => handleCloseContent()}>Close</p>
    </div>
  );
};

export default PrivacyStatement;
